import {ToastContainer} from "react-toastify";
import Nav from "./NewNavBar";
import React from "react";
import Footer from "./footer";
import {MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBRow} from "mdbreact";
import RangeTypeQuestion from "./RangeTypeQuestion";
import Authentication from "./authentication";
import CheckBoxTypeQuestion from "./CheckBoxTypeQuestion";
import SingleAnswerQuestion from "./SingleAnswerQuestion";
import {useParams} from "react-router";
import {connect} from "react-redux";


class QuestionsPage extends React.Component{

    constructor() {
        super();

        this.state = {
            data: null,
            submit_status: null
        }
    }

    componentDidMount() {
        if(localStorage.getItem("profile") == null) {
            this.getUserProfile()
        }else {
            this.getQuestions()
        }

    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                if(response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    }));
                    this.props.user_profile({
                        is_registration_complete: response.data.is_registration_complete,
                        is_seen_videos: response.data.is_seen_videos,
                        is_verified: response.data.is_verified
                    })
                    this.getQuestions()
                }

            })
            .catch((error) => {


            });
    }

    render() {
        if(localStorage.getItem('token') == null){
            return(

                <Authentication/>
            )
        }
        if(this.state.submit_status == null) {
            if (this.state.data != null) {
                return (
                    <body className="text-right bg-body" dir="rtl">
                    <ToastContainer/>
                    <Nav/>
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow>
                        <MDBCol></MDBCol>
                        <MDBCol md='6' className="justify-content-center align-items-center h-100 text-center">
                            {this.state.data.questions.map((item, index) => (
                                this.getQuestionItem(item, index)
                            ))}
                        </MDBCol>
                        <MDBCol></MDBCol>
                    </MDBRow>
                    <MDBRow className={'p-4 m-4'}>
                        <MDBCol></MDBCol>
                        <MDBCol md='12' className="justify-content-center align-items-center h-100 text-center">

                            <button className="btn btn-success w-50" onClick={(v) => this.submitAnswers(v)}>
                                <label style={{fontWeight: "bold", fontSize: "12px"}}>ارسال</label>
                            </button>

                        </MDBCol>
                        <MDBCol></MDBCol>

                    </MDBRow>
                    <Footer/>
                    </body>
                );
            } else {
                return (
                    <div></div>
                );
            }
        }else {
            return (
                <body className="text-right bg-body" dir="rtl">
                <Nav/>
                <MDBRow className="mt-5 pt-4"></MDBRow>
                <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                    <MDBCard alighment="center">
                        <MDBCardBody>
                            <MDBCardTitle>ثبت نام</MDBCardTitle>
                            <MDBCardText>
                                ثبت نام شما با موفقیت انجام شد
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBRow>
                <Footer/>
                </body>
            );
        }
    }

    submitAnswers(st){
        var answers = localStorage.getItem("answers")
        if(answers == null){

        }else {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    question: answers
                })

            };
            fetch('/api/v1/submit-answers/', requestOptions)

                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    if (response.status === 401) {
                        this.setState()
                    } else {
                        if(response.status == 202) {
                            localStorage.removeItem('answers')
                            this.setState({submit_status: 202});
                        }
                    }
                });
        }
    }
    getQuestionItem(item, index){
        if(item.type === 1) {
            return (
                <div>
                    <SingleAnswerQuestion
                        question={item.question}
                        question_id={item.id}
                        answers={item.answers}
                        listener={this.setSingleAnswers}
                    />
                </div>
            )
        }

        if(item.type === 2) {
            return (
                <div>
                    <CheckBoxTypeQuestion
                        question={item.question}
                        question_id={item.id}
                        answers={item.answers}
                        listener={this.setCheckBoxQuestionAnswer}
                    />
                </div>
            )
        }

        if(item.type === 3) {
            return (
                <div>
                    <RangeTypeQuestion
                        question={item.question}
                        question_id={item.id}
                        answers={item.answers}
                        listener={this.setRangeQuestionAnswers}
                    />
                </div>
            )
        }
    }

    setRangeQuestionAnswers(st, qid, ansid, range){
        var answers = localStorage.getItem("answers")
        if(answers==null){
            answers = {}
            answers[qid] = {answer_id: {[ansid]: range}, range: true}
            localStorage.setItem("answers", JSON.stringify(answers))
        }else {
            answers = JSON.parse(answers)
            if(qid in answers){
                answers[qid].answer_id[ansid] = range
                answers[qid].range = true
            }else {
                answers[qid] = {answer_id: {[ansid]: range}, range: true}
            }

            localStorage.setItem("answers", JSON.stringify(answers))
        }
    }

    setCheckBoxQuestionAnswer(st, qid, ansid){
        var answers = localStorage.getItem("answers")
        if(answers==null){
            answers = {}
            answers[qid] = {answer_id: [ansid], range: false}
            localStorage.setItem("answers", JSON.stringify(answers))
        }else {
            answers = JSON.parse(answers)
            if(qid in answers){
                if(answers[qid].answer_id.includes(ansid)){
                    var index = answers[qid].answer_id.indexOf(ansid)
                    answers[qid].answer_id.splice(index, 1)
                }else {
                    answers[qid].answer_id.push(ansid)
                }
            }else {
                answers[qid] = {answer_id: [ansid], range: false}
            }
            localStorage.setItem("answers", JSON.stringify(answers))
        }
    }

    setSingleAnswers(st, qid, ansid){
        var answers = localStorage.getItem("answers")
        if(answers==null){
            answers = {}
            answers[qid] = {answer_id: [ansid], range: false}
            localStorage.setItem("answers", JSON.stringify(answers))
        }else {
            answers = JSON.parse(answers)
            answers[qid] = {answer_id: [ansid], range: false}
            localStorage.setItem("answers", JSON.stringify(answers))
        }
    }

    getQuestions(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            }
        };
        fetch('/api/v1/questions-list/', requestOptions)
            .then(res => res.json())
            .then((response) => {
                if (response.status === 401) {
                    this.setState()
                } else {
                    this.setState({data: response});
                }
            });
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function  mapState(state){
    return {
        user_profile: state.user_profile
    }
}

const updateProfile =(dispatch)=>{
    return{
        user_profile: (item)=>{ dispatch({type: 'UPDATE_PROFILE', item}) }
    }
}

export default connect(mapState, updateProfile)(withParams(QuestionsPage));