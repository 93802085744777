import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import React, { Component } from 'react';
import {
    MDBCollapse,
    MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler,
} from 'mdb-react-ui-kit';
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { FaSignInAlt, FaUser, FaUserAlt } from "react-icons/fa";
import { BrowserView, MobileView } from "react-device-detect";
import { Button } from "@mui/material";
import { withTranslation } from 'react-i18next';
import { LanguageContext } from "../LanguageContext";
import { useState } from 'react';
import { MDBDropdown } from 'mdb-react-ui-kit';
import ReactDOM from "react-dom";
import zIndex from '@mui/material/styles/zIndex';


class Nav extends Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            page_name: props.page

        }


    }

    componentDidMount() {
        this.chooseLang();

    }

    chooseLang() {
        var l = localStorage.getItem("lang")
        if (l !== null) {
            const { setLanguage } = this.context;
            setLanguage(l)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.chooseLang();
    }

    desktopView() {
        return (
            <MDBNavbar expand='lg' light bgColor='light' style={{ direction: 'ltr' }}>
                <MDBContainer fluid>
                    <MDBNavbarBrand href='/' style={{ paddingLeft: '120px' }}>
                        <img
                            src='/logo192.png'
                            height='40'
                            alt='کانون هدایت'
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => this.setState({ open: !this.state.open })}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    {/* <MDBCollapse navbar center show={this.state.open}>

                        {this.getItems(false)}


                    </MDBCollapse> */}

                    {this.getButton(false)}
                </MDBContainer>
            </MDBNavbar>
        );
    }

    mobileView() {
        if (this.props.i18n.language === 'fa') {
            return (
                <MDBNavbar expand='lg' light bgColor='light' className={'p-3'} style={{ direction: 'ltr' }}>
                    <MDBContainer fluid>
                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='20'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar center show={this.state.open}>

                            {this.getItems(true)}


                        </MDBCollapse>

                    </MDBContainer>
                </MDBNavbar>
            );
        } else {
            return (
                <MDBNavbar expand='lg' light bgColor='light' className={'p-3 text-start'} style={{ direction: 'ltr' }}>
                    <MDBContainer fluid>
                        <MDBNavbarBrand href='/'>
                            <img
                                src='/logo192.png'
                                height='20'
                                alt='کانون هدایت'
                                loading='lazy'
                            />
                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => this.setState({ open: !this.state.open })}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar center show={this.state.open}>

                            {this.getItems(true)}


                        </MDBCollapse>

                    </MDBContainer>
                </MDBNavbar>
            );
        }

    }

    render() {
        return (
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        )
    }



    getButton(isMobile) {
        const { setLanguage } = this.context;

        const changeLangBTN = (newLanguage) => {
            localStorage.setItem("lang", newLanguage);
            setLanguage(newLanguage);
        };

        if (isMobile) {
            if (this.props.user_profile !== undefined &&
                this.props.user_profile !== null &&
                localStorage.getItem("token") !== null &&
                this.state.page_name !== 'logout' &&
                this.props.user_profile.is_registration_complete !== undefined &&
                this.props.user_profile.is_verified !== undefined
            ) {
                return (
                    <div>

                        <Button
                            className={'p-2'}
                            onClick={(v) => this.gotoProfile(v)}
                            variant="contained"
                            sx={{
                                border: 1,
                                boxShadow: 0,
                                backgroundColor: "#FFF",
                                fontFamily: 'IRANSansX',
                                fontSize: "10px",
                                color: "#848484",
                                borderRadius: '5px',
                                ":hover": {
                                    bgcolor: "#BEE3DB",
                                    color: "#fff",
                                    boxShadow: 0,
                                }
                            }}
                        >
                            <label style={{ fontFamily: 'IRANSansX' }}> <FaUser /> {this.props.t("profile_btn")} </label>
                        </Button>

                        <div className={'mt-3'}>
                            <MDBIcon flag='uk' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('en')} />
                            <MDBIcon flag='ir' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('fa')} />
                        </div>

                    </div>
                )
            } else {
                return (
                    <div>
                        {/* <a href='/authentication' className='text-decoration-none'>
                            <button className="btn" style={{ background: '#FF686B', color: '#fff' }} >
                                <label style={{ fontFamily: 'IRANSansX' }}><FaSignInAlt /> {this.props.t("profile_register_login_btn")}</label>
                            </button>
                        </a> */}
                        <div className='mt-4'>
                            <a href="/authentication" className="text-decoration-none" onClick={() => sessionStorage.setItem("needRegister", "true")}>
                                <label style={{ fontFamily: 'IRANSansX' }}>{this.props.t("signup")}</label>
                            </a>
                        </div>
                        <div className='mt-3'>

                            <a href='/authentication' className='text-decoration-none'>

                                <label style={{ fontFamily: 'IRANSansX' }}>{this.props.t("login")}</label>

                            </a>
                        </div>

                        <div className={'mt-3'}>
                            <MDBIcon flag='uk' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('en')} />
                            <MDBIcon flag='ir' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('fa')} />
                        </div>

                    </div>
                )
            }
        }
        if (this.props.user_profile !== undefined &&
            this.props.user_profile !== null &&
            localStorage.getItem("token") !== null &&
            this.state.page_name !== 'logout' &&
            this.props.user_profile.is_registration_complete !== undefined &&
            this.props.user_profile.is_verified !== undefined
        ) {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>

                    <Button
                        style={{ marginRight: '20px' }}
                        className={'p-2'}
                        onClick={(v) => this.gotoProfile(v)}
                        variant="contained"
                        sx={{
                            border: 1,
                            boxShadow: 0,
                            backgroundColor: "#FFF",
                            fontSize: "10px",
                            fontFamily: 'IRANSansX',
                            color: "#848484",
                            borderRadius: '5px',
                            ":hover": {
                                bgcolor: "#BEE3DB",
                                color: "#fff",
                                boxShadow: 0,
                            }
                        }}
                    >
                        <label style={{ fontFamily: 'IRANSansX' }}> <FaUser /> {this.props.t("profile_btn")} </label>
                    </Button>

                    <LanguageDropdown setLanguage={setLanguage} />
                    {/* <MDBIcon flag='uk' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={(e) => changeLangBTN('en')} />
                    <MDBIcon flag='ir' style={{ cursor: 'pointer' }} onClick={(e) => changeLangBTN('fa')} /> */}


                </div>
            )
        } else {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <a href="/authentication"
                        onClick={(e) => {
                            e.preventDefault(); 
                            sessionStorage.setItem("needRegister", "true"); 
                            window.location.href = "/authentication"; 
                        }}
                        className='text-decoration-none'
                        style={{
                            fontWeight: 'bold',
                            paddingRight: '20px',
                            fontFamily: 'IRANSansX',
                            color: '#355070',
                            width: '120px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            textDecoration: 'none'
                        }}>
                        {this.props.t("signup")}
                    </a>

                    <a href='/authentication' className='text-decoration-none' style={{ paddingRight: '20px' }}>
                        <button className="btn"
                            style={{
                                background: '#355070',
                                width: '120px',
                                height: '50px',
                                fontFamily: 'IRANSansX',
                                color: '#fff',
                                borderRadius: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', 
                                padding: '5px 10px',
                                gap: '5px' 
                            }}>
                            <FaUserAlt />
                            <span style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
                                {this.props.t("login")}
                            </span>
                        </button>
                    </a>

                    <LanguageDropdown setLanguage={setLanguage} />
                </div>
            )
        }
    }

    gotoProfile(v) {
        // window.location.href = '/my-profile'
        window.location.href = '/my-profile'
    }

    getItems(isMobile) {

        if (isMobile) {
            return (
                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-center'>

                    {/* <MDBNavbarItem>
                        <MDBNavbarLink aria-current='page' href='/' style={{ fontWeight: 'bold' }}>
                            {this.props.t("home")}
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink href='/about-us' style={{ fontWeight: 'bold' }}>{this.props.t("aboutus")}</MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink href='/contact-us' style={{ fontWeight: 'bold' }}>{this.props.t("contactus")}</MDBNavbarLink>
                    </MDBNavbarItem> */}

                    <MDBNavbarItem>
                        {this.getButton(isMobile)}
                    </MDBNavbarItem>

                </MDBNavbarNav>
            );

        } else {
            return (
                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 justify-content-center'>

                    <MDBNavbarItem>
                        <MDBNavbarLink href='/contact-us' style={{ fontWeight: 'bold', marginRight: '25px' }}>{this.props.t("contact_us_footer_title")}</MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink href='/about-us' style={{ fontWeight: 'bold', marginRight: '25px' }}>{this.props.t("aboutus")}</MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink aria-current='page' href='/' style={{ fontWeight: 'bold' }}>
                            {this.props.t("home")}
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                </MDBNavbarNav>
            );
        }
    }
}

function mapState(state) {
    return {
        user_profile: state.user_profile
    }
}
function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}
const LanguageDropdown = ({ setLanguage }) => {
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lang") || "en");

    const handleLangChange = (lang) => {
        localStorage.setItem("lang", lang);
        setLanguage(lang);
        setSelectedLang(lang);
    };
    const [isOpen, setIsOpen] = useState(false);
    const lan = selectedLang === "en" ? "uk" : "ir";
    return (
        <MDBDropdown>
            <MDBIcon
                flag={lan}
                style={{ cursor: "pointer" }}
                onClick={() => setIsOpen(true)}
            />
            {selectedLang === "en" ? "GB" : "IR"}

            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        handleLangChange("en");
                    }}
                    className="dropdown-item"
                >
                    <MDBIcon flag="uk" /> English (GB)
                </a>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        handleLangChange("fa");
                    }}
                    className="dropdown-item"
                >
                    <MDBIcon flag="ir" /> Persian (IR)
                </a>
            </Modal>
        </MDBDropdown>
    );
};


const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null; // Ensure it doesn't render when closed

    // Check if document.body exists before rendering
    const modalRoot = typeof document !== "undefined" ? document.body : null;
    if (!modalRoot) return null;

    return ReactDOM.createPortal(
        <div className=" fixed left-0 w-full h-full flex justify-center items-start bg-opacity-30 z-50"
            style={{
                position: "fixed",
                top: '95px',
                right: '15px',
                zIndex: '998',
                width: '280px',
                borderRadius: '15px',
                background: 'white'
            }}>
            <div className=" p-4 rounded-lg shadow-lg w-180 mt-10 relative">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <h6 style={{ fontSize: "1.125rem", fontWeight: "600", whiteSpace: "nowrap", flex: "1", overflow: "hidden", textOverflow: "ellipsis" }}>
                        Select Language
                    </h6>
                    <a
                        onClick={onClose}
                        style={{ fontSize: "1.125rem", color: "gray", cursor: "pointer", marginLeft: "16px" }}
                        onMouseOver={(e) => (e.target.style.color = "black")}
                        onMouseOut={(e) => (e.target.style.color = "gray")}
                    >
                        &times;
                    </a>
                </div>



                {children}
            </div>
        </div>,
        modalRoot // Ensure it renders outside Nav
    );
};

export default connect(mapState)(withParams(withTranslation()(Nav)));
