import React, { Component } from "react";


import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBRow } from "mdbreact";
import Footer from "./footer";
import Nav from "./NewNavBar";
import YouTube from "react-youtube";
import { useParams } from "react-router";
import { connect } from "react-redux";
import NewSlider from "./newSlider";
import { BrowserView, MobileView } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import { fontSize, height, margin, maxHeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import ReactPlayer from "react-player";

class Home_page extends Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            is_block: false,
            lang: this.props.params.lang,
        }

        this.i18n = this.props.i18n;
        this.scrollContainerRef = React.createRef();

    }



    chooseLang() {
        if (this.state.lang === 'fa') {
            localStorage.setItem("lang", this.state.lang);
        } else {
            if (this.state.lang === 'en') {
                localStorage.setItem("lang", this.state.lang);
            } else {
                if (localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'en');
                }
            }
        }


        var l = localStorage.getItem("lang")
        this.languageChangeListener = () => {
            fetch('/api/v1/main-page/?lang=' + this.i18n.language)
                .then(res => res.json())
                .then(json => {
                    this.getUserProfile(json)

                });


        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            // alert(l)
            const { setLanguage } = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }

        this.get_main_data(l);
    }


    componentDidMount() {
        this.chooseLang()

        const checkInterval = setInterval(() => {
            if (!this.scrollContainerRef || !this.scrollContainerRef.current) {
                return;
            }

            const children = this.scrollContainerRef.current.children;

            if (children.length < 2) {
                return;
            }

            clearInterval(checkInterval);

            const secondCol = children[1];
            const offset = secondCol.offsetLeft - (this.scrollContainerRef.current.clientWidth / 2) + (secondCol.clientWidth / 2);
            if (this.i18n.language === 'fa') {
                this.scrollContainerRef.current.scrollTo({ left: offset - 65, behavior: "smooth" });
            } else {
                this.scrollContainerRef.current.scrollTo({ left: offset + 55, behavior: "smooth" });

            }
        }, 1000);

    }

    get_main_data(l) {
        fetch('/api/v1/main-page/?lang=' + l)
            .then(res => res.json())
            .then(json => {
                this.getUserProfile(json)

            });
    }

    getUserProfile(data) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };
        fetch('/api/v1/profile/', requestOptions)
            .then((response) => {
                if (!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                localStorage.setItem('profile', JSON.stringify({
                    is_registration_complete: response.data.is_registration_complete,
                    is_seen_videos: response.data.is_seen_videos,
                    is_verified: response.data.is_verified
                }));
                this.props.user_profile({
                    is_registration_complete: response.data.is_registration_complete,
                    is_seen_videos: response.data.is_seen_videos,
                    is_verified: response.data.is_verified
                })
                this.setState({ data: data });
            })
            .catch((error) => {
                if (error === 401) {
                    this.setState({ data: data })
                } else {
                    if (error === 403) {
                        this.setState({ data: data })
                    } else {
                        if (error === 429) {
                            this.setState({ data: data })
                        }
                    }
                }

            });
    }

    mobileView() {

        if (this.state.is_block) {
            return (
                <body className="text-right bg-body" dir="rtl">
                    <Nav />
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                        <MDBCard alighment="center">
                            <MDBCardBody>
                                <MDBCardTitle>خطا</MDBCardTitle>
                                <MDBCardText>
                                    {this.props.i18n.t('your_account_is_suspended_error')}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBRow>
                    <Footer
                        isEnglish={false}
                    />
                </body>
            );
        }

        if (this.state.data !== null) {
            const opts = {
                height: '230',
                width: '270',
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                },
            };
            return this.getMainPageDataMobile(opts);
        }
        return (
            <div>
                {/*<ErrorToGetData/>*/}
            </div>
        );
    }

    getMainPageDataMobile(opts) {
        if (this.i18n.language === 'fa') {
            return (
                <div className="text-right container-fluid p-0" dir="rtl" style={{ color: "black", background: "white" }}>
                    <Nav />
                    <MDBRow className="m-0 p-0" style={{ width: "100vw", overflow: "hidden" }}>
                        <div style={{
                            position: "relative",
                            width: "100vw",
                            height: "auto",
                            margin: "0",
                            padding: "0"
                        }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100vw",
                                    height: "50vh",
                                    overflow: "hidden",
                                }}
                            >
                                <ReactPlayer
                                    url="videos/aimmatch_hero.mp4"
                                    playing
                                    loop
                                    muted
                                    controls={false}
                                    height="50vh"
                                    width="auto"
                                />
                            </div>

                            <img src="images/HeroS.png" alt="Hero"
                                style={{
                                    position: "absolute",
                                    top: "85%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "22%",
                                    maxWidth: "300px"
                                }}
                            />
                        </div>
                    </MDBRow>


                    <MDBRow className="justify-content-center d-flex h-70 text-center p-0 m-0" style={{ marginRight: '10px', marginLeft: '10px' }}>
                        <div style={{ width: "95%" }}>
                            <h2 className={'mb-3 rtl'}
                                style={{ fontWeight: 'bold', marginTop: "10px" }}>بستری برای مسلمانانی که به دنبال پیوندهای معنادار و ازدواج مبتنی بر ایمان هستند.</h2>
                        </div>
                    </MDBRow>

                    <MDBRow
                        className="d-flex justify-content-center align-items-center p-0 m-0"
                        style={{ textAlign: "center" }}
                    >
                        <img
                            src="images/phone.png"
                            alt="Phone"
                            style={{
                                width: "70%",
                                maxWidth: "360px",
                                position: "relative",
                                bottom: "-45px",
                                marginTop: "-60px",
                                zIndex: "10",
                            }}
                        />
                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center text-center  m-0 p-0"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "auto",
                            position: "relative",
                            overflow: "visible",
                            width: "100%",
                            margin: "0"

                        }}>


                        <MDBRow style={{ marginTop: '50px' }}>
                            <MDBCol size={2} className="text-end">
                                <img src="images/Icon1.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                            </MDBCol>
                            <MDBCol class={" text-end"} size={10} style={{ fontSize: '15px', textAlign: 'right' }}>
                                <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>ثبت نام سریع و آسان</h5>
                                <p className={'rtl'} > پروفایل خود را در کمترین زمان ایجاد کنید و جستجو را شروع کنید! </p>
                            </MDBCol>
                        </MDBRow>


                        <MDBRow>
                            <MDBCol size={2} className="text-end">
                                <img src="images/Icon2.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                            </MDBCol>
                            <MDBCol class={" text-end"} size={10} style={{ fontSize: '15px', textAlign: 'right' }}>
                                <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>امنیت و اعتماد</h5>
                                <p className={'rtl'} >پروفایل های واقعی، تأیید هویت، و فضای امن برای تعاملات سالم.</p>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size={2} className="text-end">
                                <img src="images/Icon3.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                            </MDBCol>
                            <MDBCol class={" text-end"} size={10} style={{ fontSize: '15px', textAlign: 'right' }}>
                                <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>ارتباطات معنی دار</h5>
                                <p className={'rtl'} > فرصت هایی برای ملاقات و ارتباط بر اساس ارزش ها و ایمان مشترک. </p>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size={2} className="text-end">
                                <img src="images/Icon4.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                            </MDBCol>
                            <MDBCol class={" text-end"} size={10} style={{ fontSize: '15px', textAlign: 'right' }}>
                                <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>دسترسی جهانی</h5>
                                <p className={'rtl'} > به راحتی با مسلمانان از سراسر جهان ارتباط برقرار کنید. </p>
                            </MDBCol>
                        </MDBRow>

                    </MDBRow>



                    <MDBRow className="justify-content-center align-items-center h-100  rtl p-0 m-0" style={{ marginTop: "50px" }}>

                        <img src="images/phone3.png" style={{ width: "160px", marginTop: '50px' }} />

                        <div style={{ marginTop: '50px', fontSize: '1.2em' }}>

                            <ol className=" text-end">
                                <li className="mt-3"><strong>ایجاد یک پروفایل:</strong> اطلاعات خود را تکمیل کنید و ترجیحات خود را مشخص کنید.</li>
                                <li className="mt-3"><strong> جستجو: </strong> پروفایل های مناسب را مرور کنید و با موارد مشابه آشنا شوید.</li>
                                <li className="mt-3">  <strong> ارتباط:</strong>علاقه خود را از طریق پیام ها نشان دهید و گفتگو را شروع کنید. </li>
                            </ol>
                        </div>

                    </MDBRow>


                    <MDBRow className="rtl justify-content-center align-items-center text-center  m-0 p-0"
                        style={{
                            backgroundColor: "#F0F0F0",
                            width: "100%",
                            margin: "0",


                        }}>
                        <div style={{ width: "95%", marginTop: '10px' }}>
                            <h2 style={{ fontWeight: "bold" }}> امروز به ما بپیوندید و سفر خود را به سوی یک رابطه موفق آغاز کنید!</h2>
                            <input
                                type="button"
                                onClick={() => {
                                    sessionStorage.setItem("needRegister", "true");
                                    window.location.href = "/authentication";
                                }}
                                value={this.i18n.t('startnow')}
                                className="mt-3"
                                style={{
                                    width: "160px",
                                    color: "#fff",
                                    backgroundColor: "#355070",
                                    borderRadius: "8px",
                                    height: "40px"
                                }}
                            />                        </div>
                    </MDBRow>

                    <MDBRow
                        className="justify-content-center align-items-center  rtl"
                        style={{ marginRight: '10px', marginLeft: '10px' }}
                    >

                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "100%", marginTop: '30px' }}>

                            <h1 style={{ fontWeight: "bold" }}>{this.i18n.t('memberexprience')}</h1>
                        </div>
                        <div
                            ref={this.scrollContainerRef}

                            style={{
                                display: "flex",
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                                gap: "40%",
                                width: "100%",
                                paddingBottom: "10px",
                                scrollbarWidth: "thin",
                                scrollBehavior: "smooth",
                            }}
                        >

                            <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                                <div className="mb-2 d-flex align-items-start">
                                    <img src="images/member_1.png" style={{ width: "35px", borderRadius: "50%" }} />
                                    <p className="ms-2 m-0">یوسف</p>
                                </div>

                                <div className="p-2"
                                    style={{
                                        width: "230px",
                                        height: "100px",
                                        backgroundColor: "#FFF",
                                        borderRadius: "10px 0px 10px 10px",
                                        textAlign: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                        padding: "10px",
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        flexDirection: "column",
                                        boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px'
                                    }}
                                >
                                    <p className="m-0"
                                        style={{
                                            fontWeight: "bold",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            textAlign: "center",
                                            fontSize: "14px"
                                        }}
                                    >
                                        "محیط اینجا به من این امکان را داد که با اطمینان به دنبال همسرم بگردم. این یک تجربه شگفت انگیز بود!"
                                    </p>
                                </div>
                            </MDBCol>

                            <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                                <div className="mb-2 d-flex align-items-end">
                                    <img src="images/member_2.png" style={{ width: "35px", borderRadius: "50%" }} />
                                    <p className="ms-2 m-0">فاطمه</p>
                                </div>

                                <div className="p-2"
                                    style={{
                                        width: "230px",
                                        height: "100px",
                                        backgroundColor: "#FFF",
                                        borderRadius: "10px 0px 10px 10px",
                                        boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px',
                                        textAlign: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                        padding: "10px",
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        flexDirection: "column"
                                    }}
                                >
                                    <p className="m-0"
                                        style={{
                                            fontWeight: "bold",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            textAlign: "center",
                                            fontSize: "14px"
                                        }}
                                    >
                                        "نمی‌توانستم بستر بهتری را برای یافتن یک ارتباط جدی و معنادار تصور کنم.
                                        این دقیقاً همان چیزی بود که به آن نیاز داشتم!"
                                    </p>
                                </div>
                            </MDBCol>

                            <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                                <div className="mb-2 d-flex align-items-end">
                                    <img src="images/member_3.png" style={{ width: "35px", borderRadius: "50%" }} />
                                    <p className="ms-2 m-0">نادیا</p>
                                </div>

                                <div className="p-2"
                                    style={{
                                        width: "230px",
                                        height: "100px",
                                        backgroundColor: "#FFF",
                                        borderRadius: "10px 0px 10px 10px",
                                        textAlign: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                        padding: "10px",
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        flexDirection: "column",
                                        boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px'
                                    }}
                                >
                                    <p className="m-0"
                                        style={{
                                            fontWeight: "bold",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            textAlign: "center",
                                            fontSize: "14px"
                                        }}
                                    >
                                        "هرگز فکر نمی کردم کسی را پیدا کنم که واقعاً ارزش ها و اهداف من را درک کند، اما این مکان این امکان را فراهم کرد."
                                    </p>
                                </div>
                            </MDBCol>


                        </div>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center text-center m-1 " style={{ height: '400px' }}>
                        <div className="rounded p-3">
                            <MDBRow>
                                <YouTube videoId="Eey7gjGZz9s" opts={opts} className={'p-0'} />
                                {/* <YouTube videoId="Z6EGhTawlO8" opts={opts} className={'p-0'}/> */}
                                {/*<YouTube videoId="B5fBvRS42sU" opts={opts} className={'p-0'}/>*/}
                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="d-flex justify-content-center position-relative m-0 p-0 w-100"
                        style={{ zIndex: 45, marginTop: "150px", marginBottom: "-200px" }}>

                        <div className="d-flex justify-content-center align-items-center position-relative w-100" style={{ marginBottom: '-50px' }}>

                            <div style={{ position: "relative", marginLeft: "-30px", zIndex: 1, top: "-50px" }}>
                                <img src="images/woman.png"
                                    style={{
                                        width: "135px",
                                        borderRadius: "50%",
                                        maxWidth: "100%"
                                    }} />
                            </div>

                            <div style={{ position: "relative", marginRight: "-30px", zIndex: 2 }}>
                                <img src="images/man.png"
                                    style={{
                                        width: "135px",
                                        borderRadius: "50%",
                                        maxWidth: "100%"
                                    }} />
                            </div>
                        </div>

                    </MDBRow>





                    <MDBRow className="ltr justify-content-center align-items-center text-center p-4"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "350px",
                            position: "relative",
                            width: "100%",
                            margin: "0"
                        }}>




                        <div className="d-flex align-items-center justify-content-center rtl" style={{ zIndex: 3 }}>
                            <div>
                                <div style={{ textAlign: "right", marginRight: "30px" }}>
                                    <h3>آیا برای یافتن نیمه گمشده خود آماده اید؟</h3>
                                    <h5>اکنون ثبت نام کنید و جستجوی خود را شروع کنید!</h5>
                                </div>
                                <input
                                    type="button"
                                    onClick={() => {
                                        sessionStorage.setItem("needRegister", "true");
                                        window.location.href = "/authentication";
                                    }}
                                    value={this.i18n.t('signup')}
                                    className="mt-3"
                                    style={{
                                        width: "150px",
                                        color: "#fff",
                                        backgroundColor: "#355070",
                                        borderRadius: "8px",
                                        height: "40px"
                                    }}
                                />
                                />
                            </div>
                        </div>

                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center h-100 text-center rtl" style={{ margin: "40px 10px" }}>
                        <div className="rounded p-3">
                            <h3>ما مشتاقانه منتظر شنیدن نظرات شما برای بهبود خدمات ما هستیم!</h3>
                        </div>
                    </MDBRow>


                    <Footer
                        isEnglish={false}
                    />
                </div>
            );
        } else {
            return (
                <div className="text-right container-fluid p-0" dir="ltr" style={{ color: 'black', background: "white" }}>
                    <Nav />


                    <MDBRow className="m-0 p-0" style={{ width: "100vw", overflow: "hidden" }}>
                        <div style={{
                            position: "relative",
                            width: "100vw",
                            height: "auto",
                            margin: "0",
                            padding: "0"
                        }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100vw",
                                    height: "50vh",
                                    overflow: "hidden",
                                }}
                            >
                                <ReactPlayer
                                    url="videos/aimmatch_hero.mp4"
                                    playing
                                    loop
                                    muted
                                    controls={false}
                                    height="50vh"
                                    width="auto"
                                />
                            </div>

                            <img src="images/HeroS.png" alt="Hero"
                                style={{
                                    position: "absolute",
                                    top: "85%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "22%",
                                    maxWidth: "300px"
                                }}
                            />
                        </div>
                    </MDBRow>


                    <MDBRow className="justify-content-center d-flex h-70 text-center p-0 m-0" style={{ marginRight: '10px', marginLeft: '10px' }}>
                        <div style={{ width: "95%" }}>
                            <h2 className={'mb-3 rtl'}
                                style={{ fontWeight: 'bold', marginTop: "10px" }}>A platform for Muslims seeking meaningful connections and faith-based marriage.</h2>
                        </div>
                    </MDBRow>

                    <MDBRow
                        className="d-flex justify-content-center align-items-center p-0 m-0"
                        style={{ textAlign: "center" }}
                    >
                        <img
                            src="images/phone.png"
                            alt="Phone"
                            style={{
                                width: "70%",
                                maxWidth: "360px",
                                position: "relative",
                                bottom: "-45px",
                                marginTop: "-60px",
                                zIndex: "10",
                                msOverflow: "visible"
                            }}
                        />
                    </MDBRow>



                    <MDBRow className="justify-content-center align-items-center text-center p-0 m-0"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "auto",
                            position: "relative",
                            overflow: "visible",

                        }}>


                        <MDBRow style={{ marginTop: '50px' }}>
                            <MDBCol size={2} className="text-end">
                                <img src="images/Icon1.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                            </MDBCol>
                            <MDBCol class={" text-start"} size={10} style={{ fontSize: '15px', textAlign: 'left' }}>
                                <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>Quick and Easy Registration</h5>
                                <p> Create your profile in no time and start searching! </p>
                            </MDBCol>
                        </MDBRow>


                        <MDBRow>
                            <MDBCol size={2} className="text-end">
                                <img src="images/Icon2.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                            </MDBCol>
                            <MDBCol class={" text-start"} size={10} style={{ fontSize: '15px', textAlign: 'left' }}>
                                <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>Security and Trust</h5>
                                <p >Real profiles, identity verification, and a secure space for healthy interactions.</p>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size={2} className="text-end">
                                <img src="images/Icon3.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                            </MDBCol>
                            <MDBCol class={" text-start"} size={10} style={{ fontSize: '15px', textAlign: 'left' }}>
                                <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>Meaningful Connections</h5>
                                <p> Opportunities to meet and connect based on shared values and faith. </p>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size={2} className="text-end">
                                <img src="images/Icon4.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                            </MDBCol>
                            <MDBCol class={" text-end"} size={10} style={{ fontSize: '15px', textAlign: 'left' }}>
                                <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>Global Accessibility</h5>
                                <p> Easily connect with Muslims from around the world. </p>
                            </MDBCol>
                        </MDBRow>

                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center h-100 ltr p-0 m-0" style={{ marginTop: "50px" }}>

                        <img src="images/phone3.png" style={{ width: "160px", marginTop: '50px' }} />

                        <div style={{ marginTop: '50px', fontSize: '1.3em' }}>

                            <ol className=" text-start">
                                <li className="mt-3"><strong> Create a Profile:</strong> Complete your information and define your preferences.</li>
                                <li className="mt-3"><strong>Search:</strong>Browse through suitable profiles and get to know potential matches.</li>
                                <li className="mt-3"><strong> Connect:</strong> Show your interest through messages and start a conversation.</li>
                            </ol>
                        </div>

                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center text-center p-0 m-0"
                        style={{
                            backgroundColor: "#F0F0F0",

                        }}>
                        <div style={{ width: "95%", marginTop: '15px', marginBottom: '15px' }}>
                            <h2 style={{ fontWeight: "bold" }}> Join us today and start your journey toward a successful relationship!</h2>
                            <input
                                type="button"
                                onClick={() => {
                                    sessionStorage.setItem("needRegister", "true");
                                    window.location.href = "/authentication";
                                }}
                                value={this.i18n.t('startnow')}
                                className="mt-3"
                                style={{
                                    width: "160px",
                                    color: "#fff",
                                    backgroundColor: "#355070",
                                    borderRadius: "8px",
                                    height: "40px"
                                }}
                            />                        </div>
                    </MDBRow>




                    <MDBRow
                        className="justify-content-center align-items-center p-0 m-0"
                        style={{ marginRight: '10px', marginLeft: '10px' }}
                    >
                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "100%", marginTop: '30px' }}>
                            <h1 style={{ fontWeight: "bold" }}>{this.i18n.t('memberexprience')}</h1>
                        </div>

                        <div
                            ref={this.scrollContainerRef}
                            style={{
                                scrollBehavior: "smooth",
                                display: "flex",
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                                gap: "40%",
                                width: "100%",
                                paddingBottom: "10px",
                                scrollbarWidth: "thin",
                            }}
                        >

                            <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                                <div className="mb-2 d-flex align-items-start">
                                    <img src="images/member_1.png" style={{ width: "35px", borderRadius: "50%" }} />
                                    <p className="ms-2 m-0">Yusuf</p>
                                </div>

                                <div className="p-2"
                                    style={{
                                        width: "230px",
                                        height: "100px",
                                        backgroundColor: "#FFF",
                                        borderRadius: "10px 0px 10px 10px",
                                        textAlign: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                        padding: "10px",
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        flexDirection: "column",
                                        boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px'

                                    }}
                                >
                                    <p className="m-0"
                                        style={{
                                            fontWeight: "bold",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            textAlign: "center",
                                            fontSize: "14px"
                                        }}
                                    >
                                        "The environment here allowed me to search for my soulmate with confidence. It was an amazing experience!"                                    </p>
                                </div>
                            </MDBCol>

                            <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                                <div className="mb-2 d-flex align-items-end">
                                    <img src="images/member_2.png" style={{ width: "35px", borderRadius: "50%" }} />
                                    <p className="ms-2 m-0">Fatima</p>
                                </div>

                                <div className="p-2"
                                    style={{
                                        width: "230px",
                                        height: "100px",
                                        backgroundColor: "#FFF",
                                        borderRadius: "10px 0px 10px 10px",
                                        textAlign: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                        padding: "10px",
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        flexDirection: "column",
                                        boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px'

                                    }}
                                >
                                    <p className="m-0"
                                        style={{
                                            fontWeight: "bold",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            textAlign: "center",
                                            fontSize: "14px"
                                        }}
                                    >
                                        "I couldn’t have imagined a better platform to find a serious and meaningful connection. It was exactly what I needed!"
                                    </p>
                                </div>
                            </MDBCol>

                            <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                                <div className="mb-2 d-flex align-items-end">
                                    <img src="images/member_3.png" style={{ width: "35px", borderRadius: "50%" }} />
                                    <p className="ms-2 m-0">Nadia</p>
                                </div>

                                <div className="p-2"
                                    style={{
                                        width: "230px",
                                        height: "100px",
                                        backgroundColor: "#FFF",
                                        borderRadius: "10px 0px 10px 10px",
                                        textAlign: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                        padding: "10px",
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                        flexDirection: "column",
                                        boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px'

                                    }}
                                >
                                    <p className="m-0"
                                        style={{
                                            fontWeight: "bold",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            textAlign: "center",
                                            fontSize: "14px"
                                        }}
                                    >
                                        "I never thought I’d find someone who truly understands my values and goals, but this place made it possible."                                    </p>
                                </div>
                            </MDBCol>


                        </div>
                    </MDBRow>




                    <MDBRow className="justify-content-center align-items-center text-center m-1 " style={{ height: '400px' }}>
                        <div className="rounded p-3">
                            <MDBRow>

                                {/*<YouTube videoId="eni3M0-fpXo" opts={opts} className={'p-0'}/>*/}
                                {/* <YouTube videoId="Ck_Z87sd_Jg" opts={opts} className={'p-0'}/> */}
                                <YouTube videoId="YIT7ZDRPkqw" opts={opts} className={'p-0'} />


                            </MDBRow>
                        </div>
                    </MDBRow>



                    <MDBRow className="d-flex justify-content-center position-relative m-0 p-0 w-100"
                        style={{ zIndex: 45, marginTop: "150px", marginBottom: "-200px" }}>

                        <div className="d-flex justify-content-center align-items-center position-relative w-100" style={{ marginBottom: '-50px' }}>

                            <div style={{ position: "relative", left: "5px", zIndex: 2 }}>
                                <img src="images/man.png"
                                    style={{
                                        width: "135px",
                                        borderRadius: "50%",
                                        marginRight: "-15px"
                                    }} />
                            </div>

                            <div style={{ position: "relative", zIndex: 1, top: "-50px" }}>
                                <img src="images/woman.png"
                                    style={{
                                        width: "135px",
                                        borderRadius: "50%",
                                        marginLeft: "-15px"
                                    }} />
                            </div>

                        </div>

                    </MDBRow>


                    <MDBRow className="ltr justify-content-center align-items-center text-center p-0 m-0"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "250px",
                            position: "relative",

                        }}>


                        <div className="d-flex align-items-center justify-content-center" style={{ zIndex: 3 }}>
                            <div>
                                <div style={{ textAlign: "center", marginLeft: "15px" }}>
                                    <h4 style={{ fontWeight: "bold" }}>Are you ready to find your missing half?</h4>
                                    <h6>Sign up now and start your search!</h6>
                                </div>
                                <input
                                    type="button"
                                    onClick={() => {
                                        sessionStorage.setItem("needRegister", "true");
                                        window.location.href = "/authentication";
                                    }}
                                    value={this.i18n.t('signup')}
                                    className="mt-3"
                                    style={{
                                        width: "150px",
                                        color: "#fff",
                                        backgroundColor: "#355070",
                                        borderRadius: "8px",
                                        height: "40px"
                                    }}
                                />
                            </div>
                        </div>

                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-0" style={{ margin: "40px 10px" }}>
                        <div className="rounded p-3">
                            <h5>We are looking forward to hearing from you to improve our services</h5>
                        </div>
                    </MDBRow>
                    <Footer
                        isEnglish={true}
                    />
                </div>
            );
        }
    }

    desktopView() {
        if (this.state.is_block) {
            return (
                <body className="text-right bg-body" dir="rtl">
                    <Nav />
                    <MDBRow className="mt-5 pt-4"></MDBRow>
                    <MDBRow className="justify-content-center align-items-center h-100 text-center m-1" >
                        <MDBCard alighment="center">
                            <MDBCardBody>
                                <MDBCardTitle>خطا</MDBCardTitle>
                                <MDBCardText>
                                    حساب کاربری شما توسط ادمین مسدود شده است با پشتیبانی تماس حاصل فرمایید.
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBRow>
                    <Footer
                        isEnglish={false}
                    />
                </body>
            );
        }

        if (this.state.data !== null) {
            const opts = {
                height: '310',
                width: '510',
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                },
            };

            return this.getPageDataDesktop(opts);
        }
        return (
            <div>

            </div>
        );
    }

    getPageDataDesktop(opts) {
        if (this.i18n.language === 'fa') {
            return (
                <div className="text-right" dir="rtl" style={{ width: '100%', color: "black", background: "white" }} >
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        background: 'white',
                        zIndex: 1000,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.49)'
                    }}>


                        <Nav />
                    </div>
                    <MDBRow className="m-0 p-0" style={{ width: "100vw", overflow: "hidden" }}>
                        <div style={{
                            position: "relative",
                            width: "100vw",
                            height: "auto",
                            margin: "0",
                            padding: "0"
                        }}>
                            <div style={{ width: "100%", overflow: "hidden" }}>
                                <ReactPlayer
                                    url="videos/aimmatch_hero.mp4"
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="auto"
                                    controls={false}
                                />
                            </div>
                            <img src="images/HeroS.png" alt="Hero"
                                style={{
                                    position: "absolute",
                                    top: "80%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "22%",
                                    maxWidth: "300px"
                                }}
                            />
                        </div>
                    </MDBRow>
                    <MDBRow className="justify-content-center d-flex h-70 text-center p-4" style={{ marginRight: '90px', marginLeft: '100px', height: "300px" }}>
                        <div style={{ width: "85%" }}>
                            <h2 className={'mb-3 ltr'}
                                style={{ fontWeight: 'bold', marginTop: "10px", color: "black", fontSize: "3rem" }}>بستری برای مسلمانانی که به دنبال پیوندهای معنادار و ازدواج مبتنی بر ایمان هستند</h2>
                        </div>
                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center text-center p-4"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "400px",
                            position: "relative",
                            overflow: "visible",
                            width: "100%",
                            margin: "0"
                        }}>

                        <MDBCol class={"rtl"} style={{ marginRight: '140px' }}>

                            <MDBRow>
                                <MDBCol size={3} className="text-start">
                                    <img src="images/Icon1.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                                </MDBCol>
                                <MDBCol class={" text-end"} size={9} style={{ fontSize: '15px', textAlign: 'right' }}>
                                    <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>ثبت نام سریع و آسان</h5>
                                    <p className={'rtl'} > پروفایل خود را در کمترین زمان ایجاد کنید و جستجو را شروع کنید! </p>
                                </MDBCol>
                            </MDBRow>


                            <MDBRow>
                                <MDBCol size={3} className="text-start">
                                    <img src="images/Icon2.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                                </MDBCol>
                                <MDBCol class={" text-end"} size={9} style={{ fontSize: '15px', textAlign: 'right' }}>
                                    <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>امنیت و اعتماد</h5>
                                    <p className={'rtl'} >پروفایل های واقعی، تأیید هویت، و فضای امن برای تعاملات سالم.</p>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol size={3} className="text-start">
                                    <img src="images/Icon3.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                                </MDBCol>
                                <MDBCol class={" text-end"} size={9} style={{ fontSize: '15px', textAlign: 'right' }}>
                                    <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>ارتباطات معنی دار</h5>
                                    <p className={'rtl'} > فرصت هایی برای ملاقات و ارتباط بر اساس ارزش ها و ایمان مشترک. </p>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol size={3} className="text-start">
                                    <img src="images/Icon4.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                                </MDBCol>
                                <MDBCol class={" text-end"} size={9} style={{ fontSize: '15px', textAlign: 'right' }}>
                                    <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>دسترسی جهانی</h5>
                                    <p className={'rtl'} > به راحتی با مسلمانان از سراسر جهان ارتباط برقرار کنید. </p>
                                </MDBCol>
                            </MDBRow>

                        </MDBCol>
                        <MDBCol
                            className="rtl"
                            style={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                marginLeft: "150px",
                                overflow: "visible",
                            }}
                        >
                            <img
                                src="images/phone.png"
                                alt="Phone"
                                style={{
                                    width: "460px",
                                    position: "absolute",
                                    bottom: "-150px",
                                }}
                            />
                        </MDBCol>

                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4 rtl" style={{ marginRight: '90px', marginLeft: '100px', color: "black" }}>
                        <MDBCol size={3}>
                            <img src="images/phone3.png" style={{ width: "200px" }} />
                        </MDBCol>


                        <MDBCol size={9} className="rtl">
                            <ol className=" text-end" style={{ fontSize: '1.6em' }}>
                                <li><strong>ایجاد یک پروفایل:</strong> اطلاعات خود را تکمیل کنید و ترجیحات خود را مشخص کنید.</li>
                                <li style={{ marginRight: "35px", marginTop: "25px" }}><strong> جستجو: </strong> پروفایل های مناسب را مرور کنید و با موارد مشابه آشنا شوید.</li>
                                <li style={{ marginRight: "70px", marginTop: "25px" }}>  <strong> ارتباط:</strong>علاقه خود را از طریق پیام ها نشان دهید و گفتگو را شروع کنید. </li>
                            </ol>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="rtl justify-content-center align-items-center text-center p-4"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "210px",
                            maxHeight: "300px",
                            overflowX: "hidden",
                            width: "100%",
                            margin: "0"
                        }}>
                        <div style={{ width: "50%" }}>
                            <h1 style={{ fontWeight: "bold" }}> امروز به ما بپیوندید و سفر خود را به سوی یک رابطه موفق آغاز کنید!</h1>
                            <input
                                type="button"
                                onClick={() => {
                                    sessionStorage.setItem("needRegister", "true");
                                    window.location.href = "/authentication";
                                }}
                                value={this.i18n.t('startnow')}
                                className="mt-3"
                                style={{
                                    width: "160px",
                                    color: "#fff",
                                    backgroundColor: "#355070",
                                    borderRadius: "8px",
                                    height: "40px"
                                }}
                            />                        </div>
                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4 rtl" style={{ width: '80%', justifySelf: 'center' }}>


                        <h1 style={{ fontWeight: "bold", marginTop: "50px", marginBottom: "50px" }}> {this.i18n.t('memberexprience')}</h1>

                        <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                            <div className="mb-2 d-flex align-items-start">
                                <img src="images/member_1.png" style={{ width: "35px", borderRadius: "50%" }} />
                                <p className="ms-2 m-0">یوسف</p>
                            </div>
                            <div className="p-2 align-items-center" style={{ width: "85%", height: '110px', backgroundColor: "#FFF", borderRadius: "10px 0px 10px 10px", boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px', textAlign: "center", marginRight: "20px" }}>
                                <p className="m-0" style={{ fontWeight: "bold" }}>
                                    "محیط اینجا به من این امکان را داد که با اطمینان به دنبال همسرم بگردم. این یک تجربه شگفت انگیز بود!"
                                </p>
                            </div>
                        </MDBCol>
                        <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                            <div className="mb-2 d-flex align-items-end">
                                <img src="images/member_2.png" style={{ width: "35px", borderRadius: "50%" }} />
                                <p className="ms-2 m-0">فاطمه</p>
                            </div>
                            <div className="p-2 align-items-center" style={{ width: "85%", height: '110px', backgroundColor: "#FFF", borderRadius: "10px 0px 10px 10px", boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px', textAlign: "center", marginRight: "20px" }}>
                                <p className="m-0" style={{ fontWeight: "bold" }}>
                                    "نمی‌توانستم بستر بهتری را برای یافتن یک ارتباط جدی و معنادار تصور کنم. این دقیقاً همان چیزی بود که به آن نیاز داشتم!"
                                </p>
                            </div>
                        </MDBCol>
                        <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                            <div className="mb-2 d-flex align-items-end">
                                <img src="images/member_3.png" style={{ width: "35px", borderRadius: "50%" }} />
                                <p className="ms-2 m-0">نادیا</p>
                            </div>
                            <div className="p-2 align-items-center" style={{ width: "85%", height: '110px', backgroundColor: "#FFF", borderRadius: "10px 0px 10px 10px", boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px', textAlign: "center", marginRight: "20px" }}>
                                <p className="m-0 align-items-center" style={{ fontWeight: "bold" }}>
                                    هرگز فکر نمی کردم کسی را پیدا کنم که واقعاً ارزش ها و اهداف من را درک کند، اما این مکان این امکان را فراهم کرد.
                                </p>
                            </div>

                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-center align-items-center h-150 text-center p-4" style={{ marginRight: '90px', marginLeft: '100px' }}>
                        <div className="rounded p-3">
                            <MDBRow>
                                <YouTube
                                    videoId="Eey7gjGZz9s"
                                    opts={{
                                        height: '500',
                                        width: '900',
                                        playerVars: {
                                            autoplay: 1,
                                            controls: 1,
                                            modestbranding: 1
                                        }
                                    }} />
                                {/* <YouTube videoId="Z6EGhTawlO8" opts={opts} /> */}
                                {/*<YouTube videoId="B5fBvRS42sU" opts={opts} />*/}

                            </MDBRow>
                        </div>
                    </MDBRow>

                    <MDBRow className="ltr justify-content-center align-items-center text-center p-4"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "350px",
                            overflowX: "hidden",
                            width: "100%",
                            margin: "0"
                        }}>
                        <MDBCol size={8} >

                            <div className="d-flex align-items-center justify-content-center rtl">
                                <div className="position-relative" style={{ marginTop: "-3%", transform: "scaleX(-1)" }}>
                                    <img src="images/man.png"
                                        style={{ width: "180px", borderRadius: "50%", position: "relative", zIndex: 2, marginRight: "-50px", marginBottom: "-150px" }} />
                                    <img src="images/woman.png"
                                        style={{ width: "180px", borderRadius: "50%", position: "relative", zIndex: 1 }} />
                                </div>

                                <div>
                                    <div style={{ textAlign: "right", marginRight: "30px", color: "black" }}>
                                        <h1>آیا برای یافتن نیمه گمشده خود آماده اید؟</h1>
                                        <h3>اکنون ثبت نام کنید و جستجوی خود را شروع کنید!</h3>
                                    </div>
                                    <input
                                        type="button"
                                        onClick={() => {
                                            sessionStorage.setItem("needRegister", "true");
                                            window.location.href = "/authentication";
                                        }}
                                        value={this.i18n.t('signup')}
                                        className="mt-3"
                                        style={{
                                            width: "150px",
                                            color: "#fff",
                                            backgroundColor: "#355070",
                                            borderRadius: "8px",
                                            height: "40px"
                                        }}
                                    />
                                </div>

                            </div>


                        </MDBCol>

                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4 rtl" style={{ marginRight: '90px', marginLeft: '100px', color: "black" }}>
                        <div className="rounded p-3">
                            <h3>ما مشتاقانه منتظر شنیدن نظرات شما برای بهبود خدمات ما هستیم!</h3>
                        </div>
                    </MDBRow>

                    <Footer
                        isEnglish={false}
                    />

                </div>
            );
        } else {
            return (
                <div className="text-right p-0 " dir="rtl" style={{ width: '100%', color: "black", background: 'white' }} >
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        background: 'white',
                        zIndex: 1000,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.49)'
                    }}>
                        <Nav />
                    </div>

                    <MDBRow className="m-0 p-0" style={{ width: "100vw", overflow: "hidden" }}>
                        <div style={{
                            position: "relative",
                            width: "100vw",
                            height: "auto",
                            margin: "0",
                            padding: "0"
                        }}>
                            <div style={{ width: "100%", overflow: "hidden" }}>
                                <ReactPlayer
                                    url="videos/aimmatch_hero.mp4"
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="auto"
                                    controls={false}
                                />
                            </div>

                            <img src="images/HeroS.png" alt="Hero"
                                style={{
                                    position: "absolute",
                                    top: "80%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "22%",
                                    maxWidth: "300px"
                                }}
                            />
                        </div>
                    </MDBRow>


                    <MDBRow className="justify-content-center d-flex h-70 text-center p-4" style={{ marginRight: '90px', marginLeft: '100px', height: "300px" }}>
                        <div style={{ width: "85%" }}>
                            <h2 className={'mb-3 ltr'}
                                style={{ fontWeight: 'bold', marginTop: "10px", color: "black", fontSize: "3rem" }}>A platform for Muslims seeking meaningful connections and faith-based marriage</h2>
                        </div>
                    </MDBRow>


                    <MDBRow className="justify-content-center align-items-center text-center p-4"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "400px",
                            position: "relative",
                            overflow: "visible",
                            // overflowX: "hidden",
                            width: "100%",
                            margin: "0"
                        }}>
                        <MDBCol
                            className="rtl"
                            style={{

                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                marginLeft: "150px",
                                overflow: "visible",
                            }}
                        >
                            <img
                                src="images/phone.png"
                                alt="Phone"
                                style={{
                                    width: "460px",
                                    position: "absolute",
                                    bottom: "-150px",
                                }}
                            />
                        </MDBCol>

                        <MDBCol class={"ltr"} style={{ marginLeft: '140px' }}>

                            <MDBRow>
                                <MDBCol class={" text-start"} size={9} style={{ fontSize: '15px', textAlign: 'left' }}>
                                    <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>Quick and Easy Registration</h5>
                                    <p className={'ltr'} > Create your profile in no time and start searching! </p>
                                </MDBCol>
                                <MDBCol size={3} className="text-end">
                                    <img src="images/Icon1.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                                </MDBCol>
                            </MDBRow>


                            <MDBRow>
                                <MDBCol class={" text-start"} size={9} style={{ fontSize: '15px', textAlign: 'left' }}>
                                    <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>Security and Trust</h5>
                                    <p className={'ltr'} > Real profiles, identity verification, and a secure space for healthy interactions. </p>
                                </MDBCol>
                                <MDBCol size={3} className="text-end">
                                    <img src="images/Icon2.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol class={" text-start"} size={9} style={{ fontSize: '15px', textAlign: 'left' }}>
                                    <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>Meaningful Connections</h5>
                                    <p className={'ltr'} > Opportunities to meet and connect based on shared values and faith. </p>
                                </MDBCol>
                                <MDBCol size={3} className="text-end">
                                    <img src="images/Icon3.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol class={" text-start"} size={9} style={{ fontSize: '15px', textAlign: 'left' }}>
                                    <h5 style={{ fontWeight: "bold", marginTop: "6px" }}>Global Accessibility</h5>
                                    <p className={'ltr'} > Easily connect with Muslims from around the world. </p>
                                </MDBCol>
                                <MDBCol size={3} className="text-end">
                                    <img src="images/Icon4.png" alt="timing" style={{ width: "45px", height: "45px" }}></img>
                                </MDBCol>
                            </MDBRow>

                        </MDBCol>
                    </MDBRow>



                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4 ltr" style={{ marginRight: '90px', marginLeft: '100px', color: "black" }}>
                        <MDBCol size={4}>
                            <img src="images/phone3.png" style={{ width: "200px" }} />
                        </MDBCol>


                        <MDBCol size={8}>
                            <ol class={" text-start"} style={{ fontSize: '1.6em' }}>
                                <li ><strong>Create a Profile: </strong> Complete your information and define your preferences.</li>
                                <li style={{ marginLeft: "35px", marginTop: "25px" }}><strong> Search: </strong> Browse through suitable profiles and get to know potential matches.</li>
                                <li style={{ marginLeft: "70px", marginTop: "25px" }}>  <strong> Connect:</strong>Show your interest through messages and start a conversation. </li>
                            </ol>
                        </MDBCol>
                    </MDBRow>


                    <MDBRow className="ltr justify-content-center align-items-center text-center p-4"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "210px",
                            maxHeight: "300px",
                            overflowX: "hidden",
                            width: "100%",
                            margin: "0",
                        }}>
                        <div style={{ width: "50%" }}>
                            <h1 style={{ fontWeight: "bold" }}> Join us today and start your journey toward a successful relationship!</h1>
                            <input
                                type="button"
                                onClick={() => {
                                    sessionStorage.setItem("needRegister", "true");
                                    window.location.href = "/authentication";
                                }}
                                value={this.i18n.t('startnow')}
                                className="mt-3"
                                style={{
                                    width: "160px",
                                    color: "#fff",
                                    backgroundColor: "#355070",
                                    borderRadius: "8px",
                                    height: "40px"
                                }}
                            />                        </div>
                    </MDBRow>



                    <MDBRow className="ltr justify-content-center align-items-center text-center " style={{ color: "black", width: '80%', justifySelf: 'center' }}>

                        <h1 style={{ fontWeight: "bold", marginTop: "50px", marginBottom: "50px" }}> {this.i18n.t('memberexprience')}</h1>

                        <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                            <div className="mb-2 d-flex align-items-end">
                                <img src="images/member_1.png" style={{ width: "35px", borderRadius: "50%" }} />
                                <p className="ms-2 m-0">Yusuf</p>
                            </div>
                            <div className="p-2" style={{ width: "85%", backgroundColor: "#FFF", borderRadius: "0px 10px 10px 10px", boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px', textAlign: "center", marginLeft: "10px" }}>
                                <p className="m-0" style={{ fontWeight: "bold" }}>
                                    "The environment here allowed me to search for my soulmate with confidence. It was an amazing experience!"
                                </p>
                            </div>
                        </MDBCol>
                        <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                            <div className="mb-2 d-flex align-items-end">
                                <img src="images/member_2.png" style={{ width: "35px", borderRadius: "50%" }} />
                                <p className="ms-2 m-0">Fatima</p>
                            </div>
                            <div className="p-2" style={{ width: "85%", backgroundColor: "#FFF", borderRadius: "0px 10px 10px 10px", boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px', textAlign: "center", marginLeft: "10px" }}>
                                <p className="m-0" style={{ fontWeight: "bold" }}>
                                    "I couldn’t have imagined a better platform to find a serious and meaningful connection. It was exactly what I needed!"
                                </p>
                            </div>
                        </MDBCol>
                        <MDBCol size={4} className="d-flex flex-column align-items-start justify-content-center">
                            <div className="mb-2 d-flex align-items-end">
                                <img src="images/member_3.png" style={{ width: "35px", borderRadius: "50%" }} />
                                <p className="ms-2 m-0">Nadia</p>
                            </div>
                            <div className="p-2" style={{ width: "85%", backgroundColor: "#FFF", borderRadius: "0px 10px 10px 10px", boxShadow: 'rgba(50,0,0,0.16) 5px 5px 5px 5px', textAlign: "center", marginLeft: "10px" }}>
                                <p className="m-0" style={{ fontWeight: "bold" }}>
                                    "I never thought I’d find someone who truly understands my values and goals, but this place made it possible."
                                </p>
                            </div>

                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center text-center p-4"
                        style={{ marginRight: '50px', marginLeft: '50px', width: '100%' }}>
                        <div className="rounded p-3 w-100">
                            <YouTube
                                videoId="YIT7ZDRPkqw"
                                className="p-2"
                                opts={{
                                    height: '500',
                                    width: '900',
                                    playerVars: {
                                        autoplay: 1,
                                        controls: 1,
                                        modestbranding: 1
                                    }
                                }}
                            />
                        </div>
                    </MDBRow>


                    <MDBRow className="ltr justify-content-center align-items-center text-center p-4"
                        style={{
                            backgroundColor: "#F0F0F0",
                            height: "350px",
                            overflowX: "hidden",
                            width: "100%",
                            margin: "0"
                        }}>
                        <MDBCol size={8} >

                            <div className="d-flex align-items-center justify-content-center">
                                <div>
                                    <div style={{ textAlign: "left", marginRight: "30px", color: "black" }}>
                                        <h1>Are you ready to find your missing half?</h1>
                                        <h3>Sign up now and start your search!</h3>
                                    </div>
                                    <input
                                        type="button"
                                        onClick={() => {
                                            sessionStorage.setItem("needRegister", "true");
                                            window.location.href = "/authentication";
                                        }}
                                        value={this.i18n.t('signup')}
                                        className="mt-3"
                                        style={{
                                            width: "150px",
                                            color: "#fff",
                                            backgroundColor: "#355070",
                                            borderRadius: "8px",
                                            height: "40px"
                                        }}
                                    />
                                </div>

                                <div className="position-relative" style={{ marginTop: "-3%  " }}>
                                    <img src="images/man.png"
                                        style={{ width: "180px", borderRadius: "50%", position: "relative", zIndex: 2, marginRight: "-50px", marginBottom: "-150px" }} />
                                    <img src="images/woman.png"
                                        style={{ width: "180px", borderRadius: "50%", position: "relative", zIndex: 1 }} />
                                </div>
                            </div>


                        </MDBCol>

                    </MDBRow>

                    <MDBRow className="justify-content-center align-items-center h-100 text-center p-4" style={{ marginRight: '90px', marginLeft: '100px', color: "black" }}>
                        <div className="rounded p-3">
                            <h3>We are looking forward to hearing from you to improve our services</h3>
                        </div>
                    </MDBRow>

                    <Footer
                        isEnglish={true}
                    />
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        )
    }
}


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function mapState(state) {
    return {
        user_profile: state.user_profile
    }
}

const updateProfile = (dispatch) => {
    return {
        user_profile: (item) => { dispatch({ type: 'UPDATE_PROFILE', item }) }
    }
}

export default connect(mapState, updateProfile)(withParams(withTranslation()(Home_page)));
