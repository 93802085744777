import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from "mdbreact";
import { FaEdit, FaEnvelope, FaHome, FaPhone } from "react-icons/fa";
import { BrowserView, MobileView } from "react-device-detect";
import { withTranslation } from "react-i18next";


class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isEnglish: this.props.isEnglish
        }
    }

    mobileView() {
        var lang = localStorage.getItem("lang")
        if (lang === null) {
            lang = 'fa'
        }
        if (lang === 'en') {
            console.log('en')
            return (
                <MDBFooter style={{ backgroundColor: '#3A3A3A', fontSize:'0.8em' }} className='text-center text-lg-start text-muted p-4 ltr'>
                    <section className=''>
                        <MDBContainer className='text-left text-md-end mt-3 text-white'>
                            <MDBRow className='mt-2 text-left'>
                                <MDBCol>
                                    <h6 className='text-uppercase fw-bold mb-4 text-left'>{this.props.t("contact_us_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />
                                    <a href="mailto:admin@aimmatch.com" style={{ all: 'unset', display: 'flex', alignItems: 'center' }}>
                                        <MDBIcon far icon="envelope" />
                                        <label className="mb-2">  admin@aimmatch.com</label>
                                    </a>
                                    <a href="tel:+447593999124" style={{ all: 'unset' }}><label className={'mb-2'} style={{ direction: 'ltr' }}><MDBIcon fab icon="whatsapp" /> + 44 7593 999 124  </label></a>

                                    <label className={'p-0 mb-4 text-left'}><MDBIcon fas icon="map-marker-alt" />  {this.props.t("address_footer_title")}</label>
                                </MDBCol>
                                <MDBCol>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("links_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />

                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }} href='/about-us'><label>{this.props.t("aboutus")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }} href='/terms'><label>{this.props.t("terms")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }} href='/privacy'><label>{this.props.t("privacy")}</label></a>
                                    </MDBRow>
                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol>

                                    <h6 className='text-uppercase fw-bold mb-1'>{this.props.t("socials_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />
                                    <div className={'text-left'}>
                                        <a target="_blank" rel="noopener noreferrer" href='https://t.me/aimmatch' className='me-4 text-reset'>
                                            <MDBIcon fab icon="telegram" />
                                        </a>

                                        <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/@aimmatch' className='me-4 text-reset'>
                                            <MDBIcon fab icon="youtube" />
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/aimmatchapp?igsh=MTF6OHJ1b215dm8zYQ==' className='me-4 text-reset'>
                                            <MDBIcon fab icon="instagram" />
                                        </a>
                                    </div>

                                </MDBCol>
                                <MDBCol>

                                    <div className={'text-center'}>
                                        <a href='/'><img width={'100%'} className={'img-fluid'} src="../images/Logo-aimmatch.png" /></a>
                                    </div>

                                </MDBCol>
                            </MDBRow>




                        </MDBContainer>
                    </section>

                    <div className='text-center p-0 text-white mt-5' style={{ fontSize: '12px' }}>
                        © 2025 aimmatch. All rights reserved.

                    </div>
                </MDBFooter>

            );
        } else {
            return (
                <MDBFooter style={{ backgroundColor: '#3A3A3A', fontSize:'0.8em' }} className='text-center text-lg-start text-muted p-4'>
                    <section className=''>
                        <MDBContainer className='text-right text-md-end mt-5 text-white'>

                            <MDBRow>

                                <MDBCol className="rtl">
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("contact_us_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />

                                    <a href="mailto:admin@aimmatch.com" style={{ all: 'unset', display: 'flex', alignItems: 'center' }}>
                                        <MDBIcon far icon="envelope" />
                                        <label className="mb-2">  admin@aimmatch.com   </label>
                                    </a>
                                    <a href="tel:+447593999124" style={{ all: 'unset' }}><label className={'mb-2'}
                                        style={{ direction: 'ltr' }}> +
                                        44 7593 999 124 <MDBIcon fab icon="whatsapp" /></label></a>

                                    <label className={'p-0 mb-4'}><MDBIcon fas icon="map-marker-alt" />   {this.props.t("address_footer_title")}</label>
                                </MDBCol>

                                <MDBCol>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("links_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />

                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }}
                                            href='/about-us'><label>{this.props.t("aboutus")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }}
                                            href='/terms'><label>{this.props.t("terms")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }} href='/privacy'><label>{this.props.t("privacy")}</label></a>
                                    </MDBRow>

                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol className={'text-center'} >
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("socials_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />
                                    <div className={'text-left'}>
                                        <a target="_blank" rel="noopener noreferrer"
                                            href='https://t.me/aimmatch'
                                            className='me-4 text-reset'>
                                            <MDBIcon fab icon="telegram" />
                                        </a>

                                        <a target="_blank" rel="noopener noreferrer"
                                            href='https://www.youtube.com/@aimmatch'
                                            className='me-4 text-reset'>
                                            <MDBIcon fab icon="youtube" />
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer"
                                            href='https://www.instagram.com/aimmatchapp?igsh=MTF6OHJ1b215dm8zYQ=='
                                            className='me-4 text-reset'>
                                            <MDBIcon fab icon="instagram" />
                                        </a>
                                    </div>
                                </MDBCol>

                                <MDBCol>
                                    <div className='text-center'>
                                        <a href='/'><img width={'100%'} className={'img-fluid'}
                                            src="../images/Logo-aimmatch.png" /></a>
                                    </div>
                                </MDBCol>


                            </MDBRow>

                        </MDBContainer>
                    </section>

                    <div className='text-center p-0 text-white mt-5 rtl' style={{ fontSize: '12px' }}>
                        © 2025 aimmatch. All rights reserved.

                    </div>
                </MDBFooter>

            );
        }

    }
    desktopView() {
        var lang = localStorage.getItem("lang")
        if (lang === null) {
            lang = 'fa'
        }
        if (lang === 'en') {

            return (

                <MDBFooter style={{ backgroundColor: '#3A3A3A' }} className='text-center text-lg-start text-muted p-4 ltr'>
                    <section className=''>
                        <MDBContainer className='text-left text-md-end mt-5 text-white'>
                            <MDBRow className='mt-3'>
                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4 text-left'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("contact_us_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />

                                    <a href="mailto: admin@aimmatch.com" style={{ all: 'unset' }}><label className={'mb-2'}> <MDBIcon far icon="envelope" /> admin@aimmatch.com</label></a>
                                    <a href="tel:+447593999124" style={{ all: 'unset' }}><label className={'mb-2'} > <MDBIcon fab icon="whatsapp" /> + 44 7593 999 124  </label></a>
                                    <br />
                                    <label><MDBIcon fas icon="map-marker-alt" /> {this.props.t("address_footer_title")}</label>
                                </MDBCol>


                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4 text-left'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("links_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />

                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }} href='/about-us'><label>{this.props.t("aboutus")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }} href='/terms'><label>{this.props.t("terms")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }} href='/privacy'><label>{this.props.t("privacy")}</label></a>
                                    </MDBRow>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4 text-left'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("socials_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />
                                    <div className={'text-left'}>
                                        <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/aimmatchapp?igsh=MTF6OHJ1b215dm8zYQ==' className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="instagram" />
                                        </a>

                                        <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/@aimmatch' className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="youtube" />
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer" href='https://t.me/aimmatch' className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="telegram" />
                                        </a>
                                    </div>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className='mx-auto text-left mb-4'>
                                    <img width={'250'} src="../images/Logo-aimmatch.png" style={{ marginLeft: '80px' }} />

                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-0 text-white' style={{ fontSize: '12px' }}>
                        © 2025 aimmatch. All rights reserved.

                    </div>
                </MDBFooter>

            );
        } else {
            return (

                <MDBFooter style={{ backgroundColor: '#3A3A3A' }} className='text-center text-lg-start text-muted p-4 rtl'>
                    <section className=''>
                        <MDBContainer className='text-right text-md-end mt-5 text-white'>
                            <MDBRow className='mt-3'>
                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("contact_us_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />

                                    <a href="mailto: admin@aimmatch.com" style={{ all: 'unset' }}><label
                                        className={'mb-2'}><MDBIcon far icon="envelope" /> admin@aimmatch.com</label></a>
                                    <a href="tel:+447593999124" style={{ all: 'unset' }}><label className={'mb-2'}
                                        style={{ direction: 'ltr' }}> +
                                        44 7593 999 124 <MDBIcon fab icon="whatsapp" /></label></a>
                                    <br />
                                    <label><MDBIcon fas icon="map-marker-alt" /> {this.props.t("address_footer_title")}</label>
                                </MDBCol>


                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("links_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />

                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }}
                                            href='/about-us'><label>{this.props.t("aboutus")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }}
                                            href='/terms'><label>{this.props.t("terms")}</label></a>
                                    </MDBRow>
                                    <MDBRow>
                                        <a style={{ all: 'unset', cursor: 'pointer', pointerEvents: 'auto' }}
                                            href='/privacy'><label>{this.props.t("privacy")}</label></a>
                                    </MDBRow>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4'>{this.props.t("socials_footer_title")}</h6>
                                    <hr style={{ width: '100%', backgroundColor: '#fff', height: '2px' }} />
                                    <div className={'text-left'}>
                                        <a target="_blank" rel="noopener noreferrer"
                                            href='https://t.me/aimmatch'
                                            className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="telegram" />
                                        </a>

                                        <a target="_blank" rel="noopener noreferrer"
                                            href='https://www.youtube.com/@aimmatch'
                                            className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="youtube" />
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer"
                                            href='https://www.instagram.com/aimmatchapp?igsh=MTF6OHJ1b215dm8zYQ=='
                                            className='me-4 text-reset'>
                                            <MDBIcon fab size='lg' icon="instagram" />
                                        </a>
                                    </div>
                                </MDBCol>

                                <MDBCol md="4" lg="3" xl="3" className='mx-auto text-right mb-4'>
                                    <img width={'250'} src="../images/Logo-aimmatch.png" style={{ marginRight: '150px' }} />

                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-0 text-white' style={{ fontSize: '12px' }}>
                        © 2025 aimmatch. All rights reserved.
                    </div>
                </MDBFooter>

            );
        }
    }
    render() {
        return (
            <div>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        )
    }
}

export default withTranslation()(Footer);